import { IS_APP } from "@/__main__/constants.mjs";
import eventBus from "@/app/app-event-bus.mjs";
import { EVENT_TRACK_AD_VIDEO_ERROR } from "@/feature-ads/constants/events.mjs";
import {
  AD_VIDEO_SCRIPT,
  AD_VIDEO_SCRIPT_ID,
  VIDEO_PROVIDER,
} from "@/feature-ads-video/constants.mjs";
import {
  getCachedAdContainer,
  handleImpressionEvent,
} from "@/feature-ads-video/util.mjs";
import aniviewProvider from "@/feature-ads-video/video-aniview.mjs";
import { devError, devLog } from "@/util/dev.mjs";
import globals from "@/util/global-whitelist.mjs";

const ELEMENT_ID = "outstream-player-div";

let vidazooWidgetRef = null;

// @ts-ignore
// eslint-disable-next-line no-restricted-properties
const global = globalThis;
const aditudeProvider = {
  initScriptAndCreateContainer() {
    const outstreamPlayer = globals.document.createElement("div");
    outstreamPlayer.id = ELEMENT_ID;
    return outstreamPlayer;
  },
  destroy() {
    globals.document
      .getElementById(ELEMENT_ID)
      ?.removeEventListener(
        "IMPRESSION",
        aditudeProvider.eventListenerCallback,
      );
  },
  handleElementInserted() {
    const adElement = globals.document.getElementById(ELEMENT_ID);
    const adContainer = getCachedAdContainer();
    if (!adElement || !adContainer) return null;
    aditudeProvider.handleAdsVideoEvent();
    // @ts-ignore
    // eslint-disable-next-line no-restricted-properties
    global.tude = global.tude || { cmd: [] };
    global.tude.cmd.push(function () {
      global.tude.requestOutstreamAd({
        divId: ELEMENT_ID, // player will be injected into this div
        baseDivId: AD_VIDEO_SCRIPT_ID.aditude,
        playerOptions: {
          showCloseButton: true,
          dock: true,
          dockedPosition: "bottom-left",
          dockedOffset: {
            bottom: 5,
            left: 5,
          },
        },
      });
    });
    const dataset = adContainer.dataset;
    global.Raven = global.Raven || { cmd: [] };
    global.Raven.cmd.push(({ config }) => {
      config.setCustom({
        param2: dataset.pageMacro,
        param4: dataset.route,
      });
    });
  },
  handleAdsVideoEvent() {
    globals.document
      .getElementById(ELEMENT_ID)
      ?.addEventListener("IMPRESSION", aditudeProvider.eventListenerCallback);
  },
  eventListenerCallback(e) {
    handleImpressionEvent(VIDEO_PROVIDER.aditude);
    devLog("[Aditude] AdImpression", e);
  },
};

const excoProvider = {
  initScriptAndCreateContainer() {
    const placement = globals.document.createElement("div");
    const scriptEl = globals.document.createElement("script");
    placement.id = AD_VIDEO_SCRIPT_ID.exco;
    scriptEl.className = "exco-player";
    scriptEl.src = `${AD_VIDEO_SCRIPT.exco}/${AD_VIDEO_SCRIPT_ID.exco}`;
    placement.appendChild(scriptEl);
    return placement;
  },
  destroy() {
    globals.removeEventListener("message", excoProvider.eventListenerCallback);
  },
  // once the ads element insert into DOM, this event will trigger.
  handleElementInserted() {
    const adContainer = getCachedAdContainer();
    const adsEl = globals.document.getElementById(AD_VIDEO_SCRIPT_ID.exco);
    if (!adsEl || !adContainer) return;
    excoProvider.handleAdsVideoEvent();
    const dataset = adContainer.dataset;
    const config = {
      reporting: {
        utm_source: dataset.pageMacro,
        utm_medium: dataset.route,
        // Pass the value through the UTM_term=0 parameter for Web and UTM_term=1 for App
        utm_term: IS_APP ? "1" : "0",
      },
    };
    adsEl.setAttribute("data-exs-config", JSON.stringify(config));
  },
  handleAdsVideoEvent() {
    globals.addEventListener("message", excoProvider.eventListenerCallback);
  },
  eventListenerCallback(event) {
    if (event.data?.type === "exco-event") {
      const { name } = event.data;
      if (name === "ad-impression") {
        handleImpressionEvent(VIDEO_PROVIDER.exco);
        devLog("[Exco] AdImpression", event.data);
      } else if (name === "ad-error") {
        eventBus.emit(EVENT_TRACK_AD_VIDEO_ERROR, {
          provider: "exco",
          ...(event.data?.metadata || {}),
        });
        devError("Exco AdError", event.data);
      }
    }
  },
};
const vidazooProvider = {
  initScriptAndCreateContainer() {
    const placement = globals.document.createElement("div");
    const scriptEl = globals.document.createElement("script");
    placement.id = "vidazoo-widget";
    scriptEl.src = AD_VIDEO_SCRIPT.vidazoo;
    scriptEl.className = "vidazoo-player";
    scriptEl.setAttribute("data-widget-id", AD_VIDEO_SCRIPT_ID.vidazoo);
    scriptEl.setAttribute("data-widget-created", "onWidgetCreated");
    placement.appendChild(scriptEl);
    return placement;
  },
  handleElementInserted() {
    const adContainer = getCachedAdContainer();
    const scriptEl = globals.document.querySelector("script.vidazoo-player");
    if (!scriptEl || !adContainer) return;
    vidazooProvider.handleAdsVideoEvent();
    const dataset = adContainer.dataset;
    scriptEl.setAttribute("data-param1", dataset.pageMacro);
    scriptEl.setAttribute("data-param2", dataset.route);
    scriptEl.setAttribute("data-ext-bi-custom1", IS_APP ? "1" : "0");
  },
  destroy() {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    const vidazooWidget = vidazooWidgetRef?.deref();
    if (vidazooWidget) {
      vidazooWidget?.destroy();
      vidazooWidget?.off("adImpression", vidazooProvider.eventListenerCallback);
    } else {
      vidazooWidgetRef = null;
    }
  },
  handleAdsVideoEvent() {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    (window as any).onWidgetCreated = function (widget) {
      vidazooWidgetRef = new WeakRef(widget);
      widget.on("adImpression", vidazooProvider.eventListenerCallback);
    };
  },
  eventListenerCallback(data) {
    handleImpressionEvent(VIDEO_PROVIDER.vidazoo);
    devLog("[Vidazoo] AdImpression", data);
  },
};

export const VIDEO_PROVIDER_INIT = {
  [VIDEO_PROVIDER.aditude]: aditudeProvider,
  [VIDEO_PROVIDER.exco]: excoProvider,
  [VIDEO_PROVIDER.vidazoo]: vidazooProvider,
  [VIDEO_PROVIDER.aniview]: aniviewProvider,
};
