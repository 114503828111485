import { IS_APP } from "@/__main__/constants.mjs";
import eventBus from "@/app/app-event-bus.mjs";
import { EVENT_TRACK_AD_VIDEO_VIEW } from "@/feature-ads/constants/events.mjs";
import type { AdsConfig } from "@/feature-ads/data-model-ads-static.mjs";
import AdsStatic from "@/feature-ads/data-model-ads-static.mjs";
import fetchRemoteAdConfig from "@/feature-ads/fetch-remote-ad-config.mjs";
import type { VideoProviderKey } from "@/feature-ads-video/constants.mjs";
import {
  VIDEO_AD_CONTAINER_ID,
  VIDEO_PROVIDER,
  videoGlobalState,
} from "@/feature-ads-video/constants.mjs";
import { devError, devLog } from "@/util/dev.mjs";
import globals from "@/util/global-whitelist.mjs";
import hasQueryString from "@/util/has-query-string.mjs";
import rangeBucket from "@/util/range-bucket.mjs";

// @ts-ignore
// eslint-disable-next-line no-restricted-properties
const global = globalThis;
global.tude = global.tude || { cmd: [] };

export const getMemoryUsage = async () => {
  let ram = 0;
  if (IS_APP) {
    const heapStats = await globals.__BLITZ__.process.getHeapStatistics();
    ram = heapStats.usedHeapSize * 1024;
    // @ts-ignore
  } else if (globals.performance.memory) {
    // @ts-ignore
    ram = globals.performance.memory.usedJSHeapSize;
  }
  return ram;
};

export async function chooseVideoProvider() {
  if (hasQueryString("__BLITZ_EXCO__")) {
    devLog("[video-ads] Forcing Ex.co");
    return VIDEO_PROVIDER.exco;
  }
  if (hasQueryString("__BLITZ_VIDAZOO__")) {
    devLog("[video-ads] Forcing Vidazoo");
    return VIDEO_PROVIDER.vidazoo;
  }
  if (hasQueryString("__BLITZ_ADITUDE__")) {
    devLog("[video-ads] Forcing Aditude");
    return VIDEO_PROVIDER.aditude;
  }
  if (hasQueryString("__BLITZ_ANIVIEW__") || !IS_APP) {
    devLog("[video-ads] Forcing Aniview");
    return VIDEO_PROVIDER.aniview;
  }
  let adConfig: AdsConfig;
  try {
    adConfig = await fetchRemoteAdConfig();
  } catch (error) {
    devError("[video-ads]", error);
    adConfig = AdsStatic.default;
  }
  // let's check videoEnabled here, so we don't need to initialize the ads' script
  if (!adConfig.videoEnabled) return "";

  const providers = adConfig.videoProviderExperiments.providers;
  // transform the provider list into a range bucket
  const rangeBucketProviders: [number, string][] = providers.map((p) => [
    p.threshold,
    p.provider,
  ]);
  // get random provider based on reached threshold
  const providerKey = rangeBucket(rangeBucketProviders)[Math.random()];
  return VIDEO_PROVIDER[providerKey];
}

export async function handleImpressionEvent(provider: VideoProviderKey) {
  const ram = await getMemoryUsage();
  eventBus.emit(EVENT_TRACK_AD_VIDEO_VIEW, {
    provider: provider,
    ram,
  });
}

// cache ads container, we don't want to query it every time
export function getCachedAdContainer() {
  let { cachedAdContainer } = videoGlobalState;
  if (
    !cachedAdContainer ||
    !globals.document.body.contains(cachedAdContainer)
  ) {
    cachedAdContainer = globals.document.getElementById(VIDEO_AD_CONTAINER_ID);
  }
  return cachedAdContainer;
}
