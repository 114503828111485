import mainRefs from "@/__main__/refs.mjs";
import AdVideoContainer from "@/feature-ads-video/AdVideo.jsx";
import {
  setupVideoAd,
  teardownVideoAd,
} from "@/feature-ads-video/global-video-ad.mjs";
import mapOriginalRefs from "@/util/map-original-refs.mjs";

const original = mapOriginalRefs({
  mainRefComponents: mainRefs.components,
});

export function setup() {
  original.append({
    mainRefComponents: {
      floatingElementsTop: [AdVideoContainer],
    },
  });
  setupVideoAd();
}

export function teardown() {
  original.restore();
  teardownVideoAd();
}
