import eventBus from "@/app/app-event-bus.mjs";
import { EVENT_TRACK_AD_VIDEO_ERROR } from "@/feature-ads/constants/events.mjs";
import {
  AD_VIDEO_SCRIPT,
  AD_VIDEO_SCRIPT_ID,
  VIDEO_PROVIDER,
} from "@/feature-ads-video/constants.mjs";
import {
  getCachedAdContainer,
  handleImpressionEvent,
} from "@/feature-ads-video/util.mjs";
import { devLog } from "@/util/dev.mjs";
import globals from "@/util/global-whitelist.mjs";

// @ts-ignore
// eslint-disable-next-line no-restricted-properties
const global = globalThis;

const AV_PUBLISHERID = "64883070d0ef6337450a1fb5";

const aniviewProvider = {
  initScriptAndCreateContainer() {
    const scriptEl = globals.document.createElement("script");
    const scriptUrl = new URL(AD_VIDEO_SCRIPT.aniview);
    scriptEl.className = "aniview-player";
    scriptUrl.searchParams.set("AV_TAGID", AD_VIDEO_SCRIPT_ID.aniview);
    scriptUrl.searchParams.set("AV_PUBLISHERID", AV_PUBLISHERID);
    scriptEl.id = `AV${AD_VIDEO_SCRIPT_ID.aniview}`;
    scriptEl.setAttribute("data-player-api", "aniviewPlayer");
    scriptEl.src = scriptUrl.toString();
    return scriptEl;
  },
  handleElementInserted() {
    const adContainer = getCachedAdContainer();
    const scriptEl: HTMLScriptElement = globals.document.querySelector(
      "script.aniview-player",
    );
    if (!scriptEl || !adContainer) return;
    handleAdsVideoEvent();
    const dataset = adContainer.dataset;
    const scriptUrl = new URL(scriptEl.src);
    scriptUrl.searchParams.set("AV_CDIM1", dataset.pageMacro);
    scriptUrl.searchParams.set("AV_CDIM2", dataset.route);
    scriptEl.src = scriptUrl.toString();
  },
  destroy() {
    // clean if needed
  },
};

function handleAdsVideoEvent() {
  // @ts-ignore
  // eslint-disable-next-line no-restricted-properties
  global.window.aniviewPlayer = (_, player) => {
    player.on("AdImpression", handleImpressionEvent(VIDEO_PROVIDER.aniview));

    player.on("AdError", function (res) {
      eventBus.emit(EVENT_TRACK_AD_VIDEO_ERROR, {
        provider: "aniview",
      });
      devLog("[Aniview] AdError", res);
    });
  };
}

export default aniviewProvider;
