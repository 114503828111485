import { IS_APP } from "@/__main__/constants.mjs";

export const VIDEO_PROVIDER = {
  exco: "exco",
  vidazoo: "vidazoo",
  aditude: "aditude",
  aniview: "aniview",
} as const;

export type VideoProviderKey = keyof typeof VIDEO_PROVIDER;

export const AD_VIDEO_SCRIPT: Record<VideoProviderKey, string> = {
  [VIDEO_PROVIDER.exco]: "https://player.ex.co/player",
  [VIDEO_PROVIDER.vidazoo]: "https://static.vidazoo.com/basev/vwpt.js",
  [VIDEO_PROVIDER.aditude]: "",
  [VIDEO_PROVIDER.aniview]: "https://tg1.aniview.com/api/adserver/spt",
};

export const AD_VIDEO_SCRIPT_ID: Record<VideoProviderKey, string> = {
  [VIDEO_PROVIDER.exco]: "a7f387b6-a04c-4beb-993d-e93b6979a42b",
  [VIDEO_PROVIDER.vidazoo]: "6539773c4f44e1e595adc181",
  [VIDEO_PROVIDER.aditude]: "pb-slot-outstream-standalone",
  [VIDEO_PROVIDER.aniview]: IS_APP
    ? "6773d6545430767caf0f3781"
    : "67a4ee6118a3e7d6eb0a54f9",
};

export const VIDEO_AD_CONTAINER_ID = "video-ad-container";

type VideoGlobalState = {
  cachedAdContainer: null | HTMLElement;
  cachedAdsProviderEle: null | HTMLElement;
  currentProvider: undefined | VideoProviderKey;
};
export const videoGlobalState: VideoGlobalState = {
  cachedAdContainer: null, // store the element VIDEO_AD_CONTAINER_ID
  cachedAdsProviderEle: null, // store the element from each provider, it might be script or div
  currentProvider: undefined, //
};
