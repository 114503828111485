/**
 * ads video provider script only be initialized when VIDEO_AD_CONTAINER_ID element exists.
 * as this element can be added any time, so this file use MutationObserver to monitor VIDEO_AD_CONTAINER_ID be added to DOM,
 * then it will start to initialize provider's script.
 * Finally, the script will be inserted into VIDEO_AD_CONTAINER_ID.
 */

import EventEmitter from "event-lite";

import { IS_TESTING } from "@/__main__/constants.mjs";
import eventBus from "@/app/app-event-bus.mjs";
import { EVENT_TRACK_VIDEO_PROVIDER } from "@/feature-ads/constants/events.mjs";
import { isAppVisible } from "@/feature-ads/util.mjs";
import {
  VIDEO_AD_CONTAINER_ID,
  videoGlobalState,
} from "@/feature-ads-video/constants.mjs";
import {
  chooseVideoProvider,
  getCachedAdContainer,
  getMemoryUsage,
} from "@/feature-ads-video/util.mjs";
import { VIDEO_PROVIDER_INIT } from "@/feature-ads-video/video-provider-helper.mjs";
import { devLog } from "@/util/dev.mjs";
import globals from "@/util/global-whitelist.mjs";
import { safeAppend } from "@/util/helpers.mjs";

const observer =
  typeof MutationObserver !== "undefined"
    ? new MutationObserver(observerCallback)
    : null;

// const CHECK_APP_VISIBLE_TIME = 5 * 60 * 1000; // 1 minute
// let appVisibleTimer = null;

export const events = new EventEmitter();

export const setupVideoAd = async () => {
  if (!globals.document || !observer) return;
  // 1. choose video provider
  const currentProvider = await chooseVideoProvider();
  if (!currentProvider) return;
  videoGlobalState.currentProvider = currentProvider;
  devLog("[video-ads] Using Provider", videoGlobalState.currentProvider);
  // 2. init ads script and insert it to DOM if we can get the VIDEO_AD_CONTAINER_ID at this time
  videoGlobalState.cachedAdContainer = getCachedAdContainer();
  renderAds();
  // 3. backup plan, maybe VIDEO_AD_CONTAINER_ID inserted later, so we need to catch this case.
  observer.observe(globals.document.body, {
    subtree: true,
    childList: true,
  });
  devLog("[video-ads] observer.observe");
};

export function teardownVideoAd() {
  VIDEO_PROVIDER_INIT[videoGlobalState.currentProvider].destroy();
  // remove ads node
  if (videoGlobalState.cachedAdsProviderEle) {
    videoGlobalState.cachedAdsProviderEle.remove();
    videoGlobalState.cachedAdsProviderEle = null;
  }

  if (!observer) return;
  observer.disconnect();
  devLog("[video-ads] observer.disconnect");
}

// 1. init ads script
// 2. render ads script to DOM
const renderAds = () => {
  // prepare ads element and script and store them
  if (!videoGlobalState.cachedAdsProviderEle) {
    videoGlobalState.cachedAdsProviderEle =
      VIDEO_PROVIDER_INIT[
        videoGlobalState.currentProvider
      ].initScriptAndCreateContainer();
  }
  appendAdsScriptContainerToDocument();
  if (IS_TESTING) {
    videoGlobalState.cachedAdsProviderEle.dataset.testid =
      videoGlobalState.cachedAdContainer.dataset.pageMacro;
  }
};

// Render the script into VIDEO_AD_CONTAINER_ID in the page
const appendAdsScriptContainerToDocument = async () => {
  // TODO: i don't know if this one helpful for performance, but let's align it with old version and see the data difference @sdeng
  // This function is triggered when a VIDEO_AD_CONTAINER_ID element is inserted into the page. However, there is a situation where the APP is invisible when the element is inserted into the DOM, which will cause the ad script to fail to be correctly inserted into the DOM and the ad cannot be loaded.
  // Here, a polling mechanism is added. As long as the ad script is not initialized, the function is tried to continue to be called.
  const isVisible = await isAppVisible();
  if (!isVisible) {
    // retryWhenAppInvisible();
    return;
  }
  // clearRetryTimer();
  const adContainer = getCachedAdContainer();
  // Make sure VIDEO_AD_CONTAINER_ID is empty before inserting the ad script to avoid multiple insertions
  if (
    adContainer &&
    videoGlobalState.cachedAdsProviderEle &&
    !adContainer.hasChildNodes()
  ) {
    safeAppend(adContainer, videoGlobalState.cachedAdsProviderEle);
    VIDEO_PROVIDER_INIT[
      videoGlobalState.currentProvider
    ].handleElementInserted();
    reportVideoProviderEvent();
  }
};

// const retryWhenAppInvisible = () => {
//   clearRetryTimer();
//   appVisibleTimer = setTimeout(() => {
//     appendAdsScriptContainerToDocument();
//   }, CHECK_APP_VISIBLE_TIME);
// };

// const clearRetryTimer = () => {
//   if (appVisibleTimer) {
//     clearTimeout(appVisibleTimer);
//     appVisibleTimer = null;
//   }
// };

const reportVideoProviderEvent = async () => {
  const ram = await getMemoryUsage();
  eventBus.emit(EVENT_TRACK_VIDEO_PROVIDER, {
    provider: videoGlobalState.currentProvider,
    ram,
  });
};

function observerCallback(mutations) {
  for (const { addedNodes, removedNodes } of mutations) {
    for (const addedNode of addedNodes) {
      if (
        addedNode.nodeType === Node.ELEMENT_NODE &&
        addedNode.id === VIDEO_AD_CONTAINER_ID
      ) {
        videoGlobalState.cachedAdContainer = addedNode;
        renderAds();
      }
    }
    // clear reference
    for (const removedNode of removedNodes) {
      if (removedNode === videoGlobalState.cachedAdContainer) {
        videoGlobalState.cachedAdContainer = null;
      }
    }
  }
}
